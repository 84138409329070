body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h4, h3, h2, .page {
    padding: 5px;
    margin: 5px;
}

.page {
  display: inline-block;
  border: 1px lightgreen solid;
}

.pageContent {
  padding: 5px;
  width: 200px;
}

.page h4 {
  background-color: bisque;
} */