.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #81d3d7;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Header .App-bar .MuiToolbar-root{
  background-color: #81d3d7 !important;
  padding-left: 10px !important;
  top: 0 !important;
  height: 65px !important;
  width: 100% !important;
  flex-grow: 1 !important;
  left: 0 !important;
  right: 0 !important;
  display:inline-flex !important;
}

.Footer {
  position: sticky !important;
  bottom: 0 !important;
  background-color: #81d3d7 !important;
  height: 65px !important;
  width: 100% !important;
  flex-grow: 1 !important;
}


.App-title {
  color: #002a77 !important;
  font-weight: 900 !important;
  font-size: 24px !important;
  padding-left: 20px !important;
  padding-right: 40px !important;
}
.Toolbar-button-font-color {
  color: #ffffff !important;
}

.Capricorn-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/../sv-capricorn-v1/src/img/capricorn_Dock_Before.png');
  min-height: 1000px;
  min-width: 300px;
  vertical-align: top;

}

nav {
  background-size: cover;
  background-color: #ffffff;
  /* width: 1000px; */
  padding: 5px;
  margin-left: 0px;
  margin-top: 0px;
}

nav a {
  color: #002a77 !important;
  font-weight: bolder !important;
  text-decoration: none;
  /* font-size: 9px; */
  font-family: sans-serif;
  padding:  2px 6px 4px 6px;
  border-radius: 90px;
  /* background-color: white; */
  display: inline-block;
  margin: 7px;
}

/* nav a:hover {
  background-color: #a8a9ad;
} */
.css-1q39md6-MuiButtonBase-root-MuiButton-root {
  color: #002a77 !important;
  font-weight: 800 !important;
}

.Profile-menu {
  color: #ffffff !important;
  position: right !important;
}

.css-19r6kue-MuiContainer-root {
  background-color: #81d3d7 !important;
}

.MuiPaper-root .MuiPaper-elevation MuiPaper-elevation4 MuiAppBar-root .MuiAppBar-colorPrimary .MuiAppBar-positionStatic .App-bar .css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: #81d3d7 !important;
}

.MuiContainer-root .MuiContainer-maxWidthXl .css-19r6kue-MuiContainer-root {
  
  left: 0px !important;
  right: 0px !important;
  background-color: #81d3d7 !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  font-size: 90% !important;
  white-space: normal !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  /* padding */
  justify-content: left !important;
}

.About-card {
  display:flow-root !important;
  height: 100% !important;
  margin: 20px 20px 20px 20px !important;
  padding: 20px 20px 20px 20px !important;
  text-justify: full !important;
}